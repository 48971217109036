import React from 'react';
import { Container } from './styled.js';

function Copyright() {
  return (
    <Container>
      <h1>Copyright Notices</h1>
      <p>
SoBridely.com ("So Bridely", "we", "us", "our") seeks to abide by all applicable laws of the United States, including all copyright laws and in particular the U.S. Online Copyright Infringement Liability Limitation Act ("OCILLA").  OCILLA attempts to balance the interests of online service providers like So Bridely with the rights of owners and exclusive licensees of copyright in photographs, other images such as sketches, and fashion designs (collectively "Images") uploaded to So Bridely's website ("Site").  Accordingly, So Bridely has instituted the following policy:
      </p>
      <p>
        <strong>Removal of Allegedly Infringing Material</strong>: So Bridely will help copyright owners and exclusive licensees ("Copyright Holders") remove allegedly infringing Images from our Site. Contact us with any questions, etc.
      </p>
      <p>
        <strong>Certainty about Infringement</strong>: Before filing Copyright Infringement Notice, please ensure that you are reasonably certain that the Image(s) on the Site that you are complaining about truly infringe(s) your copyright.  If you are unsure as to what your rights are, or whether copyright has been infringed, please consult with a legal adviser before filing the Copyright Infringement Notice.  Please be aware that there can be negative legal consequences for someone making a false allegation of copyright infringement.
      </p>
      <p>
        <strong>Filing a Copyright Infringement Notice</strong>: To file the Copyright Infringement Notice, a Copyright Holder should fill out the online Copyright Infringement Notice form and submit it electronically (online or by email), or print and mail it) to the  postal address shown on the form.  The Copyright Infringement Notice form identifies So Bridely's authorized agent and acts as a legal affirmation that you own copyright or are the exclusive licensee of copyright in the Image(s) identified in the form.  If a Copyright Infringement Notice does not contain all requested information, So Bridely will not take action based on it.
      </p>
      <p>
        <strong>What a Copyright Infringement Notice Covers</strong>: A Copyright Infringement Notice is designed to deal with Images residing on the Site at the time the Notice is filed.  Thus, while a Notice may cover more than one Image residing on the Site at a given time, it does NOT cover any Images removed from the Site before the Notice was filed; NOR does it cover any Images uploaded to the Site after the Notice is filed. A new Notice would be required to deal with such newly-uploaded Images.
      </p>
      <p>
        <strong>After a Copyright Infringement Notice is Filed</strong>: When So Bridely receives a Notice containing the required information, it will promptly contact the Complainant, if necessary, to clarify anything in the Notice that is unclear, and thereafter it will remove the identified Images within 48 (forty-eight) hours. So Bridely will also inform the affected account holder of the details of the Copyright Infringement Notice.
      </p>
      <p>
        <strong>Repeat Infringers</strong>: So Bridely will be entitled, in its sole discretion, to terminate the accounts of any account holder that is the subject of repeated complaints from Copyright Holders.
      </p>
    </Container>
  );
}

export default Copyright;