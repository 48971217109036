import React from 'react';
//components
import Layout from '../components/Layout';
import CopyrightDesktop from '../components/Copyright';
//helpers
//styles
import './style.css';

function Copyright() {
  return (
    <Layout
      mobile={<CopyrightDesktop />}
      desktop={<CopyrightDesktop />} />
  );
}

export default Copyright;